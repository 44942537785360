@import "./../assets/scss/SharedColors.scss";
@import "./../assets/scss/SharedDimentions.scss";

// @media screen and (max-width: 1405px) {
//   .analyze-wrapper {
//     .analyze-wrapper__Result {
//       .stats {
//         .chart {
//           canvas {
//             height: 138px !important;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (min-width: 1406px) {
//   .analyze-wrapper {
//     .analyze-wrapper__Result {
//       .stats {
//         .chart {
//           canvas {
//             height: 210px !important;
//           }
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 1024px) {
  .analyze-wrapper__Result {
    .filter-level {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 10px;

      .level-item {
        gap: 10px;
      }
    }

    .level {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 25px;

      .stats {
        width: 100% !important;
        padding: 10px;
        border-radius: 6px;
        box-shadow: 0 2px 3px hsla(0deg, 0%, 4%, 0.1),
          0 0 0 1px hsla(0deg, 0%, 4%, 0.1);
      }
    }
  }
}

@media screen and (max-width: 799px) {
  .aside-menu-wrapper {
    position: fixed;
    width: $side_navbar-open-width;

    .menu-list {
      a {
        font-size: 15px !important;
      }

      .has-active-text-effect {
        li {
          a {
            font-size: 15px !important;
          }
        }
      }
    }
  }

  .body-container {
    right: 0;
    width: calc(100vw - #{$side_navbar-open-width} - 6px) !important;
  }
  .body-container.home {
    width: calc(100vw) !important;
  }
}

@media screen and (min-width: 800px) and (max-width: 1024px) {
  .has-active-text-effect {
    li {
      a {
        font-size: 13px !important;
      }
    }
  }
}

.analyze-subsite-header {
  color: white;
  background-color: $green;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.chart-legend {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  color: #666666;

  .chart-legend-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 18px;
    cursor: pointer;

    .chart-legend-box {
      min-width: 40px;
      min-height: 10px;
    }

    &.striped {
      text-decoration: line-through;
    }
  }
}

.analyze-wrapper {
  .analyze-wrapper__Empty {
    padding-top: 25px;
    text-align: center;

    i {
      font-size: 3rem;
      margin-bottom: 20px;
    }
  }

  .analyze-overflow-loader {
    width: 100%;
    height: 100%;
    z-index: 99999999;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 28%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 28%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 28%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
  }

  .analyze-wrapper__Result {
    margin-top: 30px;
    // width: 80vw;
    margin: auto;

    padding-bottom: 2%;
    padding-left: 0.5em;
    padding-right: 0.5em;
    .filter-level {
      border-bottom: 2px solid $green;
      padding-bottom: 30px;

      .is-info {
        transition: transform 0.1s ease-in-out;

        &:hover {
          transform: scale(1.2);

          .updateData {
            animation: spin 2s linear infinite;
          }
        }
      }
    }

    .analyze-stat {
      text-align: center;
    }

    .stats {
      padding: 2vw;
      align-items: center !important;
      .chart {
        margin-top: 20px;
        width: 100%;
      }
      .analyse-pie {
        width: unset;
      }
    }

    .analyze-collapse {
      // margin: 50px 0;

      .analyze-collapse-header {
        border-radius: 3px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        padding: 5px 25px;
        margin-bottom: 20px;
        cursor: pointer;
        color: $green;
        width: 100%;
        i {
          margin-right: 15px;
          margin-left: 15px;
        }
      }

      &.sub-site {
        border: 1px $blue solid;
        border-radius: 10px;
        margin-bottom: 10px;
        & > .analyze-collapse-header {
          margin-bottom: 0px;
          box-shadow: none;
          border: none;
          color: $blue;
        }
      }

      .load-curve-container {
        width: 100%;
        margin: auto;
        display: flex;
        height: 60vh;

        .loadCurve {
          width: 100%;
          display: flex;
          flex-direction: column;
          height: auto;

          .graph-container {
            width: 100%;
            flex: 3;

            #graphContainer,
            #H2graphContainer,
            #SOCgraphContainer {
              width: 100%;

              > div {
                width: 100%;
                margin: auto;
              }

              div {
                div {
                }
              }
            }
          }
        }

        .legend-load-curve {
          ul {
            display: flex;
            width: 100%;
            height: 100%;
            margin-bottom: 2rem;
            justify-content: space-evenly;
            flex-direction: row;

            li {
              height: 15px;
              display: flex;
              justify-content: left;
              align-items: center;

              p {
                padding-left: 15px;
              }

              div {
                display: block;
                width: 45px;
                height: 15px;
              }

              &:hover {
                cursor: pointer;
                font-weight: bold;
              }
            }
          }
        }

        // #graphContainer {
        //   > div {
        //     width: 100%;
        //     margin: auto;
        //   }
        // }
      }

      .graph-container {
        width: 100%;
        flex: 3;

        #graphContainer {
          div {
            div {
            }
          }
        }
      }
    }

    .custom-tooltip {
      background-color: white;
      padding: 10px 30px;
      border-radius: 4px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      margin-left: 5px;
    }

    .cost-detail-chart {
      .recharts-cartesian-axis-tick-value {
        font-size: 14px;
      }
    }

    .level-item {
      margin-top: 5%;
      margin: auto;
      flex-direction: row;

      &.stats {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        width: 0;
        margin-top: 0;
      }

      .totalEnergy,
      .totalCost,
      .unitCost {
        width: 30%; //switch to 100% when using chart-js 2
        flex: 1;
        height: 100%;
        // height: 350px;

        // .energyPrice {
        //   width: 55%;
        //   height: auto;
        //   margin: 0 auto;
        // }
      }

      &.CO2 {
        display: flex;
        justify-content: space-evenly;
        width: 50%;
      }
    }
  }

  .search-container {
    width: 90%;
    margin: 10px auto;

    input {
      font-size: 15px !important;
      box-shadow: 0 1px 6px 1px hsla(0, 0%, 4%, 0.1);
    }
  }

  .aside-loader {
    margin: 10px auto;
    width: 50px;
    height: 50px;
    border: 1px solid $green;
    border-right-color: transparent;
    border-top-color: transparent;
  }
}

.aside-menu-wrapper {
  min-height: calc(100vh - #{$navbar-height});

  .menu-list {
    & > li {
      overflow-y: hidden;
    }

    a {
      font-size: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      color: black !important;
      white-space: nowrap;
      margin-left: 5%;

      &.is-active {
        font-weight: bold;
        background: white;
        color: white;

        &:hover {
          background: $lightGray;
          color: white;
        }
      }

      &.disabled {
        cursor: not-allowed;
        // color: $greenOpac;
      }

      &.not-clickable {
        color: $green;
        cursor: default;

        &:hover {
          background-color: $green;
          color: white;
        }
      }
    }
    .has-active-text-effect {
      padding: 0;
      margin: 0;
      border: none;

      li {
        a {
          font-size: 15px;
          // text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          // padding-left: 25px;

          // &.is-active,
          &:hover {
            background: $lightGray;
            color: black !important;
          }
        }
      }
    }
  }
}

// ANIMATIONS
@keyframes spin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.aside-menu {
  .folder-collapse:not(li) {
    i {
      padding: 1em;
    }
    &:hover {
      color: $green;
      cursor: pointer;
    }
    .analyze-collapse-header {
      &.opened {
        background-color: $greenOpac;
        color: white;
      }
    }
  }
}
