.signin-wrapper {
  margin-top: 2rem !important;

  input {
    padding-left: 36px !important;
  }

  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}

.browser-info {
  margin-bottom: 2rem;
  text-align: center;

  i {
    padding-right: 10px;
  }
}

.shortcut-container {
  display: flex;
  margin-top: 2em;

  justify-content: center;
}
