@import "../../assets/scss/SharedColors.scss";

.collective-site-edit-site-form-modal {
  .modal-card {
    min-width: fit-content;
    width: 90vw;
  }
}
.collective-site-editor {
  margin-bottom: 30px;
  .site {
    padding: 15px 10px;
    border: 1px solid $green;
    border-radius: 10px;
    .site-enedis-number {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .site-report-url {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      gap: 10px;
    }

    .download-consoData {
      font-size: 1em;
      display: block;
      margin-top: 15px;

      i {
        font-size: 1em;
      }
    }

    .site-metadata {
      .site-metadata-name {
        color: $green;
        font-weight: 500;
      }

      .site-metadata-address {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .site-description {
      text-align: center;
    }

    .site-actions {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      .button {
        width: 100%;
      }

      .analyze-button {
        width: 100%;
        margin-bottom: 3px;
        color: white;

        &:hover {
          color: white;
        }
      }

      .fetching {
        width: 20px;
        height: 20px;
        margin: auto;
        border: 1.5px solid $green;

        border-right-color: transparent;
        border-top-color: transparent;
      }
    }
  }
}

.create-site-wrapper {
  .select {
    select {
      width: 100%;
    }
  }

  .tabs {
    // width: fit-content;
    margin: auto;
    margin-bottom: 20px;

    ul {
      .tarif {
        justify-content: flex-start;
      }
      justify-content: center;

      .disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }

  .step0 {
    width: 100%;
    display: flex;
    flex-direction: row;

    div {
      flex: 1;
      display: block;
      width: 100%;
      text-align: center;
      margin-left: 0;

      i {
        padding-left: 5px;
      }
    }
  }

  .step1 {
    .manual-city-selector {
      .dropdown-content {
        max-height: 13em;
        overflow: auto;
      }
    }
  }
}

.google-places-autocomplete__suggestions-container {
  background-color: #fff;
  border-color: #dbdbdb;
  color: #363636;
  box-shadow: inset 0px 0px 0px 2px rgba(121, 67, 67, 0.1);
  max-width: 100%;
  width: 100%;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;

  font-size: 1rem;

  line-height: 1.5;

  position: relative;
  vertical-align: top;

  > div {
    &:hover {
      color: white;
      font-weight: 600;
    }
  }

  .google-places-autocomplete__suggestion {
    &:hover {
      background-color: #9ebe5ab3;
      cursor: pointer;
    }

    padding: 1em;
    border: 1px rgba(121, 67, 67, 0.1) solid;
    border-right: 0;
    border-left: 0;
  }
}

.collapse {
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 5px 25px;
  margin-bottom: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #4a4a4a;
  text-align: left;
  width: 60%;
  margin: auto;
  margin-bottom: 20px;

  i {
    margin-right: 15px;
  }

  .ReactCollapse--collapse {
    margin-top: 30px;
  }
}

.tarifBuilder-container {
  padding-bottom: 10%;
}

.contract-tooltip {
  display: flex;
  width: 30%;
  margin: auto;
  justify-content: space-around;
  align-items: center;
  padding: 1em 0;

  .help {
    margin: 0 !important;
  }
}

.carousel-arrow {
  background: $green;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: white;
  }
}

.info-popup-container {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  color: "black";
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  .popup-card {
    background-color: $greenOpac;
    width: 40vw;
    height: 40vh;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
}
