.maplabel {
  background-color: rgba(0, 0, 0, 0.438);
  border: solid 2px transparent;
  border-radius: 5px;
  padding: 0.3rem !important;
  backdrop-filter: blur(2px);
}
.marker {
  z-index: 4574;
}
