@import "../assets/scss/SharedColors";

.cloud-functions-tester-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
  padding: 20px 10vw;

  textarea {
    max-width: 100%;
    min-width: 100%;
  }
}

.cloud-functions-tester-loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid $green;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.cloud-functions-tester-menu {
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  height: 100%;

  ul {
    border: 1px black solid;

    li {
      overflow-y: auto !important;

      a.is-active {
        background: $green;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
