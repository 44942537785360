.is-size-8 {
  font-size: 0.5rem !important;
}
.is-size-9 {
  font-size: 0.25rem !important;
}
.is-size-10 {
  font-size: 0.15rem !important;
}

.has-text-disabled {
  color: grey !important;
}
.has-text-muted {
  color: grey;
  font-size: 0.65rem;
}
.has-text-muted__bold {
  color: grey;
  font-size: 0.65rem;
  font-weight: 600;
}

.is-not-underline {
  text-decoration: none !important;
}

.is-alone {
  border-radius: 20px !important;
}

.is-success {
  background-color: #9dd057;
}
.is-info {
  background-color: #003b58 !important;

  &:hover {
    background-color: #044981 !important;
    transition: 0.3s ease 0s;
  }
}

.is-secondary {
  background-color: #f5f5f5 !important;
  color: black !important;
  border: #006276 1px solid !important;

  &:hover {
    transition: 0.3s ease 0s;
    background-color: #dcecf5 !important;
  }
}

.is-green {
  background-color: $green !important;
}
.is-warning {
  background-color: $warning !important;
  color: white !important;
}

.is-optim {
  background-color: $optim;
  color: white !important;
}

.is-danger {
  background-color: red !important;
}

.is-cancel {
  color: red;
}
.warningInTarif {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.fa-download {
  transition: transform ease-in-out 0.2s;
  &:hover {
    cursor: pointer !important;
    transform: scale(1.2);
  }
}
