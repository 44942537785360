#main-skeleton {
  //   background-color: red;
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  .logo-holder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    animation: 1s linear infinite alternate blur;
    img {
      height: auto;
      width: 20%;
      margin: auto;
    }
  }
}
@keyframes blur {
  0% {
    -webkit-filter: blur(10px);
  }
  50% {
    -webkit-filter: blur(5px);
  }

  100% {
    -webkit-filter: blur(0px);
  }
}
