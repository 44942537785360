$licenseAnalyzeColor: #006ce5;
$licenseOptimisationColor: #7f3083;
$licensePilotageColor: #4fd479;

.signup-wrapper {
  margin-top: 2rem !important;
  scroll-behavior: smooth;

  .has-icons-left input {
    padding-left: 36px !important;
  }

  .is-info {
    display: block;
    margin: auto;
    margin-top: 20px;
  }

  .step-subtitle {
    margin-bottom: 10px;
    color: grey;
    font-size: 0.85rem;
    font-weight: 300;
  }

  .skip-license {
    font-size: 0.75rem;
    color: #3273dc;

    i {
      margin-left: 5px;
    }
  }

  .license {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    height: 100%;

    .license-header {
      margin-bottom: 10px;
      font-size: 1.5rem;
    }

    .license-body {
      margin-bottom: 0px;

      .license-body-icon i {
        font-size: 3rem;
        padding: 20px;
        margin-bottom: 30px;
        border-radius: 50%;
        border: 3px solid transparent;
      }

      .license-body-top {
        min-height: 75px;
        margin-bottom: 20px;
        font-weight: 600;
      }

      .license-body-center {
        min-height: 50px;
      }
    }

    .license-footer {
      margin: 10px 0;

      .button {
        border: 1px solid transparent;
      }
    }
  }

  .license__Analyze {
    .license-header {
      color: $licenseAnalyzeColor;
    }

    .license-body-icon i {
      border-color: $licenseAnalyzeColor !important;
      color: $licenseAnalyzeColor !important;
    }

    .license-footer {
      button {
        background-color: $licenseAnalyzeColor;
        color: white;
        transition: all 0.2s ease;

        &:not([disabled]):hover {
          background-color: white;
          color: $licenseAnalyzeColor;
          border: 1px solid $licenseAnalyzeColor;
        }
      }
    }
  }

  .license__Optimisation {
    .license-header {
      color: $licenseOptimisationColor;
    }

    .license-body-icon i {
      border-color: $licenseOptimisationColor !important;
      color: $licenseOptimisationColor !important;
    }

    .license-footer {
      button {
        background-color: $licenseOptimisationColor;
        color: white;
        transition: all 0.2s ease;

        &:not([disabled]):hover {
          background-color: white;
          color: $licenseOptimisationColor;
          border: 1px solid $licenseOptimisationColor;
        }
      }
    }
  }

  .license__Pilotage {
    position: relative;

    .license-header {
      color: $licensePilotageColor;
    }

    .license-body-icon i {
      border-color: $licensePilotageColor !important;
      color: $licensePilotageColor !important;
    }

    .license-footer {
      position: absolute;
      bottom: 20px;
      width: 100%;
      left: 0;

      button {
        background-color: $licensePilotageColor;
        color: white;
        transition: all 0.2s ease;

        &:not([disabled]):hover {
          background-color: white;
          color: $licensePilotageColor;
          border: 1px solid $licensePilotageColor;
        }
      }
    }
  }

  .terms-container {
    overflow-y: scroll;
    height: 300px;
    margin-bottom: 50px;
    box-shadow: 0 0 8px #c1b8b8;
    padding: 1em;

    h3 {
      font-size: 25px;
      padding: 1em;
      padding-left: 0;
      font-weight: bold;
    }

    h5 {
      font-size: 20px;
      padding: 10px 0;
    }

    h6 {
      font-size: 18px;
      padding: 0.5em 0;
    }
  }
  .has-text-justified {
    text-align: justify;
    display: flex;
    align-items: center;
  }
  .checkbox {
    display: flex;
  }
  .check {
    margin-right: 3%;
  }
  .list-password {
    margin-top: 1%;
    font-size: 1rem;
  }
  .list-repassword {
    margin-top: 1%;
    font-size: 1rem;
    color: red;
  }

  .fa-check {
    color: green;
    margin-right: 1%;
  }
  .fa-close {
    color: red;
    margin-right: 1%;
  }

  .text-password {
    font-size: 1rem;
  }
  .termsAccepted {
    display: flex;
  }

  .required-field {
    display: inline-block;

    p {
      font-size: 0.85rem;
      font-style: italic;
    }
  }
}
